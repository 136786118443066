// let base_url = "http://localhost:8080/api/v1";
// let base_url_2 = "http://localhost:8080/api/v2";
// let base_url = "http://54.162.251.157:8080/api/v1";
let base_url = "https://api.scadmin.ca/api/v1";
let base_url_2 = "https://api.scadmin.ca/api/v2";

export let customer_endpoint = `${base_url}/customer`;
export let item_endpoint = `${base_url}/item`;
export let item_type_endpoint = `${base_url}/itemType`;
export let ticket_endpoint = `${base_url}/sale`;
export let ticket_endpoint_2 = `${base_url_2}/sale`;
export let qrcode_endpoint = `${base_url}/qrcode`;
export let lineItem_endpoint = `${base_url}/lineItem`;

export let login_endpoint = `${base_url}/login`;
export let logout_endpoint = `${base_url}/logout`;

export let quickbooks_get_auth_uri_endpoint = `${base_url}/quickbooks/getAuthUri`;
export let quickbooks_create_token_endpoint = `${base_url}/quickbooks/createAccessToken`;
export let quickbooks_get_token_endpoint = `${base_url}/quickbooks/getAccessToken`;
export let quickbooks_refresh_token_endpoint = `${base_url}/quickbooks/refreshAccessToken`;
export let quickbooks_get_customer_endpoint = `${base_url}/quickbooks/getCustomerInfo`;
export let quickbooks_get_all_customer_endpoint = `${base_url}/quickbooks/getCustomers`;
export let quickbooks_get_invoices_endpoint = `${base_url}/quickbooks/getInvoices`;

export let quickbooks_customer_endpoint = `${base_url}/quickbooks/customer`;
export let quickbooks_product_endpoint = `${base_url}/quickbooks/item`;
export let quickbooks_invoice_endpoint = `${base_url}/quickbooks/invoice`;

export let create_password = `${base_url}/customer/createPassword`;

// DirtCheap
export let dirtcheap_quickbooks_get_auth_uri_endpoint = `${base_url}/quickbooks/dirtcheap/getAuthUri`;
export let dirtcheap_quickbooks_create_token_endpoint = `${base_url}/quickbooks/dirtcheap/createAccessToken`;
export let dirtcheap_quickbooks_refresh_token_endpoint = `${base_url}/quickbooks/dirtcheap/refreshAccessToken`;
export let dirtcheap_quickbooks_get_all_customer_endpoint = `${base_url}/quickbooks/dirtcheap/getCustomers`;
export let dirtcheap_quickbooks_get_customer_types_endpoint = `${base_url}/quickbooks/dirtcheap/getCustomerTypes`;
export let dirtcheap_quickbooks_get_invoices_endpoint = `${base_url}/quickbooks/dirtcheap/getInvoices`;
export let dirtcheap_quickbooks_make_customer_inactive_endpoint = `${base_url}/quickbooks/dirtcheap/makeCustomerInactive`;
